var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fill-height":""}},[(!_vm.token)?_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-alert',{attrs:{"color":"red lighten-1","type":"error","dense":""}},[_vm._v(" Invalid Request ")])],1):_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"justify":"center","align":"center","no-gutters":""}},[(_vm.alerts)?_c('v-alert',{attrs:{"color":"red lighten-1","type":"error","dense":""}},[_vm._v(" "+_vm._s(_vm.alerts)+" ")]):_vm._e(),(_vm.messages)?_c('v-alert',{attrs:{"color":"green lighten-1","type":"success","dense":""}},[_vm._v(" "+_vm._s(_vm.messages)+" ")]):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-card',[_c('v-card-title',[_vm._v("Reset Password")]),_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.resetPassword.apply(null, arguments)}},model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c('v-text-field',{attrs:{"append-icon":_vm.showPassword ? 'mdi-eye' : 'mdi-eye-off',"rules":[
                  (v) => !!v || '',
                  (v) => (v && v.length >= 8) || '8 chars at least',
                ],"type":_vm.showPassword ? 'text' : 'password',"label":"New Password","hint":"At least 8 characters","counter":""},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=$$v},expression:"newPassword"}}),_c('v-text-field',{attrs:{"append-icon":_vm.showPassword ? 'mdi-eye' : 'mdi-eye-off',"rules":[
                  (v) => !!v || '',
                  (v) => (v && v == _vm.newPassword) || 'Password mismatch',
                ],"type":_vm.showPassword ? 'text' : 'password',"label":"Confirm Password","hint":"repeat password","counter":""},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}}),_c('v-card-actions',{staticClass:"justify-center"},[_c('v-btn',{staticClass:"mr-4",attrs:{"type":"submit","disabled":!_vm.token || !_vm.newPassword || !_vm.formValid,"color":"primary"}},[_vm._v(" Submit ")])],1)],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }