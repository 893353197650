<template>
  <v-container fill-height>
    <v-row justify="center" align="center" v-if="!token">
      <v-alert color="red lighten-1" type="error" dense>
        Invalid Request
      </v-alert>
    </v-row>
    <v-row justify="center" align="center" v-else>
      <v-col cols="12">
        <v-row justify="center" align="center" no-gutters>
          <v-alert color="red lighten-1" type="error" v-if="alerts" dense>
            {{ alerts }}
          </v-alert>

          <v-alert color="green lighten-1" type="success" v-if="messages" dense>
            {{ messages }}
          </v-alert>
        </v-row>
      </v-col>

      <v-col cols="12">
        <v-row justify="center" align="center" no-gutters>
          <v-card>
            <v-card-title>Reset Password</v-card-title>
            <v-card-text>
              <v-form
                v-model="formValid"
                ref="form"
                @submit.prevent="resetPassword"
                lazy-validation
              >
                <v-text-field
                  v-model="newPassword"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[
                    (v) => !!v || '',
                    (v) => (v && v.length >= 8) || '8 chars at least',
                  ]"
                  :type="showPassword ? 'text' : 'password'"
                  label="New Password"
                  hint="At least 8 characters"
                  counter
                  @click:append="showPassword = !showPassword"
                ></v-text-field>

                <v-text-field
                  v-model="confirmPassword"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[
                    (v) => !!v || '',
                    (v) => (v && v == newPassword) || 'Password mismatch',
                  ]"
                  :type="showPassword ? 'text' : 'password'"
                  label="Confirm Password"
                  hint="repeat password"
                  counter
                  @click:append="showPassword = !showPassword"
                ></v-text-field>

                <v-card-actions class="justify-center">
                  <v-btn
                    type="submit"
                    :disabled="!token || !newPassword || !formValid"
                    color="primary"
                    class="mr-4"
                  >
                    Submit
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-card-text></v-card
          ></v-row
        ></v-col
      >
    </v-row>
  </v-container>
</template>

<script>
import requests from "@/http";

export default {
  data: () => ({
    alerts: null,
    messages: null,
    showPassword: false,
    token: "",
    formValid: false,
    newPassword: "",
    confirmPassword: "",
  }),
  created() {
    this.token = this.$route.query.token;
  },
  methods: {
    resetPassword() {
      requests
        .post(`/api/auth/password/reset`, {
          token: this.token,
          password: this.newPassword,
        })
        .then((res) => {
          this.alerts = null;
          this.messages = res.data.message;
          alert(res.data.message);
          this.$router.push("/login");
        })
        .catch((err) => {
          this.messages = null;
          this.alerts = JSON.stringify(err.response.data.detail);
        });
    },
    test() {},
  },
};
</script>
